import React from 'react';
import { TouchableOpacity, Text, Platform, Image, View, StyleSheet, useWindowDimensions } from 'react-native';

import { createStackNavigator } from '@react-navigation/stack';
import { NavigationContainer } from '@react-navigation/native';

// Import screens
import OrdersScreen from '../screens/OrdersScreen';
import ViewOrderScreen from '../screens/ViewOrderScreen';

import HamburgerIcon from './../containers/HambugerIcon';
import HeaderWeb from './../containers/HeaderWeb'

import { colors } from '../constants/colors'

const Orders = createStackNavigator();


function Header({ openDrawer }) {

  const window = useWindowDimensions();

  return (
    <View style={styles.main}>
      <View style={[styles.container, {paddingHorizontal: window.width > 768 ? 30 : 0} ]}>
        <TouchableOpacity onPress={() => openDrawer()}>
         <HamburgerIcon />
       </TouchableOpacity>

       {Platform.OS == "web" ? <HeaderWeb /> : null}
      </View>
    </View>
  )
}

export const OrdersStack = (props) => (
      <Orders.Navigator
        drawerPosition="right"
        drawerType="slide"
        initialRouteName="Orders"
        screenOptions={{
          headerTintColor: colors.white,
          headerStyle: {
          elevation: 0,
          shadowOpacity: 0,
          borderBottomWidth: 1,
          borderBottomColor: colors.primary,
          backgroundColor: colors.primary,
          height: Platform.OS == "web" ? 90 : 100,
        } }} >
        <Orders.Screen
         name="Orders"
         component={OrdersScreen}
         options={{
           title: "My Orders \u00BB Puff Puff Vape Shop",
           headerTitle: () => <Header openDrawer={() => props.navigation.openDrawer()} />,
           headerTitleAlign: "left"
         }}
        >
        </Orders.Screen>
        <Orders.Screen
         name="View"
         component={ViewOrderScreen}
         options={{
           title: "View Order \u00BB Puff Puff Vape Shop",
           headerTitle: () => <Header openDrawer={() => props.navigation.openDrawer()} />,
           headerTitleAlign: "left"
         }}
        >
        </Orders.Screen>
      </Orders.Navigator>
);

const styles = StyleSheet.create({
  main: {
    flex: 1,
  },
  container: {
    width: "100%",
    flexDirection: "row",
    alignSelf: "center",
    maxWidth: 1280,
  },
  text: {
    color: colors.text,
    padding: 20,
  },
  branch: {
    paddingTop: 20,
    paddingHorizontal: 30,
    backgroundColor: colors.primary
  },
  categories: {
    paddingTop: 30,
    paddingBottom: 20,
    backgroundColor: colors.primary
  },
  items: {
    flex: 1,
  },
  itemsHeader: {
    flex: 1,
    fontSize: 24,
    fontWeight: "700",
    paddingTop: 45,
    paddingBottom: 35,
    paddingHorizontal: 30,
  }
});
