import React, { useState } from "react";
import "../styles.css";
function Maps() {

  return (
    <>
    <div class="section-maps">
      <div class="container mt-5">
        <div class="row">

          <div class="col-md-4">
            <h2>Find Us</h2>
            <p>Unit 26, Cornmill Shopping Centre, <br />Darlington, DL1 1LS.</p>

            <a href="tel:+447387085907" class="phone-link">07387 085907</a>

            <br />
            <br />
            <br />

            <h2>Hours</h2>
              <p>
                Mon-Sat: 8:30pm – 5:30pm<br />
                Sun: 9:45pm – 4:30pm<br />
              </p>
          </div>
          <div class="col-md-8">
            <iframe
            class="map"
            title="Puff Puff Vape Shop's Google Map"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2315.4024587299095!2d-1.5564057231954342!3d54.526398872654894!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x487e9b9bdf6f261d%3A0x21fc7e60a2c7f236!2sPuff%20Puff%20Vape%20Shop!5e0!3m2!1sen!2suk!4v1722344540373!5m2!1sen!2suk"
            width="100%"
            height="450"
            allowFullScreen=""
            loading="lazy"
            referrerPolicy="no-referrer-when-downgrade"
          ></iframe>
          </div>
        </div>
      </div>
      </div>
    </>
  );
}

export default Maps;