// Firebase Configuration

const firebaseCompanyConfig = {
  apiKey: "AIzaSyAWSfB7FcQpe79ue0300u89qIpXXjdlanY",
  authDomain: "yuze--puffpuff.firebaseapp.com",
  projectId: "yuze--puffpuff",
  storageBucket: "yuze--puffpuff.appspot.com",
  messagingSenderId: "390493234034",
  appId: "1:390493234034:web:fa6fc9a17e6c36cca9b3eb",
  measurementId: "G-PK0MSMCQYQ"
};

const firebaseBackOfficeConfig = {
  apiKey: "AIzaSyB9HTMpNuz5XN9Y2rHmGOnVTPIu2Ea10dQ",
  authDomain: "fyre-pos.firebaseapp.com",
  databaseURL: "https://fyre-pos.firebaseio.com",
  projectId: "fyre-pos",
  storageBucket: "fyre-pos.appspot.com",
  messagingSenderId: "179766960980",
  appId: "1:179766960980:web:9779b88a82add54ae8dec1",
  measurementId: "G-BJCJW0ZNS1"
};

export { firebaseCompanyConfig, firebaseBackOfficeConfig }
