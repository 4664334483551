const initialState = {
  itemData: [],
  itemVariantData: [],
  categoryData: [],
  departmentData: [],
  modifierData: [],
  modifierItemData: [],
  isLoading: true,
}

const itemData = (state = initialState, action) => {
  switch(action.type) {
    case "SET_ITEM_DATA":
      return { ...state, itemData: action.value, isLoading: false, };
    case "SET_ITEM_VARIANT_DATA":
      return { ...state, itemVariantData: action.value };
    case "SET_CATEGORY_DATA":
      return { ...state, categoryData: action.value };
    case "SET_DEPARTMENT_DATA":
      return { ...state, departmentData: action.value };
      case "SET_MODIFIER_DATA":
        return { ...state, modifierData: action.value };
        case "SET_MODIFIER_ITEM_DATA":
          return { ...state, modifierItemData: action.value };
          case 'RESET_ITEM_DATA':
              return initialState;
    default:
      return state;
  }
}

export default itemData;
