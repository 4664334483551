import React, { Component, useMemo } from "react";
import {
  View,
  Text,
  StyleSheet,
  Platform,
  TouchableOpacity,
  Image,
  ActivityIndicator
} from "react-native";

import { colors } from '../constants/colors';
import { fonts } from  '../constants/fonts';

import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome'
import { faSeedling } from '@fortawesome/free-solid-svg-icons/faSeedling'
import { faCarrot } from '@fortawesome/free-solid-svg-icons/faCarrot'
import { faWineGlass } from '@fortawesome/free-solid-svg-icons/faWineGlass'

import ItemAddModal from './ItemAddModal'

const OrderItem = ({ item, variants, openItemModal }) => {
  const sortedVariants = useMemo(() => {
    if (variants.length > 0) {
      return [...variants].sort((a, b) => a.price - b.price);
    }
    return variants;
  }, [variants]);

  const sortedAllergens = useMemo(() => {
    if (item.allergens) {
      let allergens = [...item.allergens].sort();
      if (allergens.includes('vegan')) {
        allergens = ['vegan', ...allergens.filter(allergen => allergen !== 'vegan')];
      }
      if (allergens.includes('vegetarian')) {
        allergens = ['vegetarian', ...allergens.filter(allergen => allergen !== 'vegetarian')];
      }
      if (allergens.includes('alcohol')) {
        allergens = ['alcohol', ...allergens.filter(allergen => allergen !== 'alcohol')];
      }
      return allergens;
    }
    return [];
  }, [item.allergens]);

  const lowestVariantPrice = sortedVariants.length > 0 ? sortedVariants[0].price : 0;

  return (
    <TouchableOpacity
      style={[styles.container, global.windowWidth > 768 ? { borderColor: colors.border, borderWidth: 1 } : { borderColor: colors.border, borderTopWidth: 1 }]}
      onPress={() => openItemModal(item)}
      disabled={item.isAvailable !== undefined && item.isAvailable === false}
    >
      <View style={styles.main}>
        {item.isAvailable !== undefined && item.isAvailable === false ? (
          <Text style={styles.unavailableLabel} adjustsFontSizeToFit allowFontScaling numberOfLines={1}>
            Unavailable
          </Text>
        ) : null}

        <Text style={[styles.name, { fontFamily: fonts.bold }]} allowFontScaling numberOfLines={1}>
          {item.name}
          {sortedAllergens.includes('vegan') && <FontAwesomeIcon style={styles.allergen} size={15} icon={faSeedling} />}
          {sortedAllergens.includes('vegetarian') && <FontAwesomeIcon style={styles.allergen} size={15} icon={faCarrot} />}
          {sortedAllergens.includes('alcohol') && <FontAwesomeIcon style={styles.allergen} size={15} icon={faWineGlass} />}
        </Text>

        {item.description && (
          <Text style={[styles.description, { fontFamily: fonts.regular }]} numberOfLines={1} allowFontScaling>
            {item.description}
          </Text>
        )}

        {variants.length > 0 ? (
          <Text style={{ fontSize: 14, fontFamily: fonts.regular, color: colors.textSecondary, justifyContent: 'flex-end' }} allowFontScaling>
            From £{(+lowestVariantPrice).toFixed(2)} {item.calories ? <Text style={styles.calories}>{'\u2022'} {item.calories} kcal</Text> : null}
          </Text>
        ) : (
          <Text style={{ fontSize: 14, fontFamily: fonts.regular, color: colors.textSecondary, justifyContent: 'flex-end' }} allowFontScaling>
            £{(+item.price).toFixed(2)} {item.calories ? <Text style={styles.calories}>{'\u2022'} {item.calories} kcal</Text> : null}
          </Text>
        )}
      </View>

      {item.image && (
        <Image
          resizeMode="cover"
          accessibilityLabel={`Image of ${item.name}`}
          style={styles.image}
          loadingIndicatorSource={<ActivityIndicator style={styles.imageLoading} color={colors.primary} />}
          source={{ uri: item.image }}
        />
      )}
    </TouchableOpacity>
  );
};

export default OrderItem;



const styles = StyleSheet.create({
  container: {
    flex: 1,
    minHeight: 126,
    flexDirection: "row"
  },
  main: {
    flex: 2,
    paddingVertical: 30,
    paddingHorizontal: 30,
    justifyContent: "center"
  },
  image: {
    flex: 1.5,
    backgroundColor: colors.grey
  },
  imageLoading: {
     flex: 1,
     justifyContent: "center",
     alignItems: "center",
  },
  unavailableLabel: {
    backgroundColor: colors.border,
    color: colors.textSecondary,
    fontSize: 12,
    marginRight: -0,
    paddingVertical: 7,
    paddingHorizontal: 12,
    marginTop: -102,
    position: "absolute",
    fontFamily: fonts.regular,
    right: 0,
  },
  name: { color: colors.text, fontSize: 14, marginBottom : 10, fontFamily: fonts.bold, justifyContent: "flex-start" },
  description: { fontSize: 13, color: colors.textSecondary, marginBottom : 10, fontFamily: fonts.regular, justifyContent: "flex-start"},
  calories: {
    opacity: 0.7
  },
  allergen: {
    color: colors.textSecondary,
    paddingLeft: 3,
    opacity: 0.9,
    position: "relative",
    top: 2,
  }
})
